import {
  addMonths,
  compareAsc,
  differenceInMonths,
  getYear,
  isAfter,
  isBefore,
  isEqual,
} from 'date-fns';
import {
  areRangesOverlapping,
  eachDayOfRange,
  getCombinedRanges,
  isContainedBy,
  isEqualRange,
  isWithinRange,
} from './date.js';
import { findLast } from './polyfills.js';
//
// Getters
//

export function getRecentLicense(show, combination, maximumInvalidMonths = 3) {
  return findLast(
    combination.licenses,
    (license) => isWithinRange(show.duration.start, {
      start: license.valid.start,
      // We add given months as a soft window
      end: addMonths(license.valid.end, maximumInvalidMonths),
    })
      && license.sport === show.sport,
  );
}

function getValidLicense(show, combination) {
  return findLast(
    combination.licenses,
    (license) => areRangesOverlapping(license.valid, show.duration)
      && license.sport === show.sport,
  );
}

export function getGroupGradeOptions(
  show,
  combination,
  gradeMembership = null,
  categoryMembership = null,
) {
  // Fetch current category membership
  const category = (categoryMembership)
    ? categoryMembership.category
    : combination.dog.categoryMemberships.find(
      (cm) => cm.category.sport === show.sport && areRangesOverlapping(cm.duration, show.duration),
    )?.category;

  // Get the current grade memberships (could be multiple for now with Junioren)
  const gradeMemberships = (gradeMembership)
    ? [gradeMembership]
    : combination.gradeMemberships.filter(
      (gm) => areRangesOverlapping(gm.duration, show.duration)
        && gm.grade.sport === show.sport,
    );

  // Get all group grades with correct category and grade(s)
  return (gradeMemberships.length) ? show.groupGrades.filter(
    (groupGrade) => (!category || groupGrade.categories.length === 0 || groupGrade.categories.some(
      (c) => category && c.id === category.id,
    )) && groupGrade.grade.grades.some(
      (g) => gradeMemberships.find((gm) => gm.grade.id === g.id),
    ),
  ) : show.groupGrades;
}

export function runsForShow(show, ignoreTournaments = false) {
  // TODO: remove when we can do better
  if (ignoreTournaments && eachDayOfRange(show.duration).length > 1) {
    return 0;
  }
  return (show.settings?.gameCourses || 0)
    + (show.settings?.agilityCourses || 0)
    + (show.settings?.jumpingCourses || 0);
}

//
// Hard checks: if these fail, the show will not be shown
//

export function isDogStopped(show, dog) {
  return isAfter(show.duration.start, dog.stopped);
}

export function hasRecentLicense(show, combination) {
  return !!getRecentLicense(show, combination);
}

export function isCombinationVisible(combination) {
  return !combination.hidden;
}

export function isDogCorrectBreed(show, dog) {
  return show.registrationAllowedBreeds.length === 0
    || show.registrationAllowedBreeds.some((b) => dog.pedigreeName && dog.breed.id === b.id);
}

export function isHandlerAllowedToRun(show, combination) {
  const license = getRecentLicense(show, combination);
  const canRunSelection = show.sport !== 'AGILITY'
    || (license && combination.handler.dutchId && license.licenseType !== 'GUEST')
    || !['SELECTION', 'MASTERS', 'TITLE', 'CHAMPIONSHIP'].find((st) => show.showType === st);

  const canRunJunior = show.sport !== 'AGILITY'
    || show.showType !== 'JUNIOR'
    || (combination.handler.yearOfBirth
      && combination.handler.yearOfBirth >= getYear(show.duration.start) - 18);

  // eslint-disable-next-line no-console
  console.assert(show.sport !== 'AGILITY'
    || show.showType !== 'JUNIOR' || combination.handler.yearOfBirth !== undefined);

  return canRunSelection && canRunJunior;
}

export function hasGroupOptions(
  show,
  combination,
  gradeMembership = null,
  categoryMembership = null,
) {
  return getGroupGradeOptions(show, combination, gradeMembership, categoryMembership).length > 0;
}

export function hasRequiredClubMembership(
  event,
  show,
  combination,
) {
  // If it is a club show, the dog's owner should be a member
  return show.showType !== 'CLUB' || combination.dog.primaryContact.clubMemberships.some(
    (cm) => cm.club.id === event.organiser.id,
  );
}

function isDefaultEligible(
  event,
  show,
  combination,
  debutant = false,
) {
  return !isDogStopped(show, combination.dog)
    && isCombinationVisible(combination)
    // XXX: we can just check if you ever had a sport license, so we show the error
    //      with the soft checks to renew your license.
    && (debutant || hasRecentLicense(show, combination))
    && isHandlerAllowedToRun(show, combination)
    && hasRequiredClubMembership(event, show, combination)
    && isDogCorrectBreed(show, combination.dog);
}

function isAgilityEligible(
  event,
  show,
  combination,
  gradeMembership = null,
  categoryMembership = null,
  debutant = false,
) {
  return isDefaultEligible(event, show, combination, debutant)
    && hasGroupOptions(show, combination, gradeMembership, categoryMembership);
}

export function isEligible(
  event,
  show,
  combination,
  gradeMembership = null,
  categoryMembership = null,
  debutant = false,
) {
  if (show.sport === 'AGILITY') {
    return isAgilityEligible(
      event,
      show,
      combination,
      gradeMembership,
      categoryMembership,
      debutant,
    );
  }
  return isDefaultEligible(event, show, combination, debutant);
}

//
// Soft checks: these will show up on the registration as an error or warning
//

export function isDogOldEnough(show, dog) {
  return differenceInMonths(show.duration.start, dog.birthday) >= 18;
}

export function canDogRunAllCourses(show, combination, registrations, replaces) {
  if (show.sport !== 'AGILITY') {
    return true;
  }

  const age = differenceInMonths(show.duration.start, combination.dog.birthday);
  const runsAllowedPerDay = (age >= 36) ? 5 : 4;

  const runs = runsForShow(show, true);

  // Check every day for total amount of runs, but ignore our own registrations
  return eachDayOfRange(show.duration).every((day) => runsAllowedPerDay >= runs + registrations
    .filter(
      (r) => isWithinRange(day, r.show.duration)
        && isWithinRange(day, r.registered)
        && r.show.sport === 'AGILITY'
        && r.combination?.dog.id === combination.dog.id
        && replaces?.id !== r.id,
    ).reduce((total, r) => total + runsForShow(r.show, true), 0));

}

export function isDogAlreadyRunning(show, combination, registrations, gradeMembership, replaces) {
  // Reduced to the question: does this dog already run in the same group grade?
  const groupGradeOptions = getGroupGradeOptions(show, combination, gradeMembership);
  return registrations.some(
    (r) => r.combination?.dog.id === combination.dog.id
      && r.combination.id !== combination.id
      && (!replaces || replaces.combination.id !== r.combination.id)
      && isContainedBy(show.duration, r.registered)
      && groupGradeOptions.find((gg) => gg.id === r.groupGrade.id),
  );
}

export function hasValidLicense(show, combination) {
  return !!getValidLicense(show, combination);
}

export function hasValidClubMembership(show, combination) {
  const license = getValidLicense(show, combination);

  // Either you have a guest license or you are a member somewhere
  return license?.licenseType === 'GUEST' || combination.handler.clubMemberships.length > 0;
}

export function isCombinationAlreadyRegistered(show, combination, registrations) {
  return registrations.some((r) => r.combination?.id === combination.id
    && r.show.id === show.id
    && isContainedBy(show.duration, r.registered));
}

export function isCombinationInvited(show, combination, invitations) {
  return !show.registrationInvitation
    || invitations === false
    || invitations?.some((i) => i.combination.id === combination.id && i.show.id === show.id);
}

function canDefaultRegister(
  show,
  combination,
  registrations,
  invitations,
  gradeMembership = null,
  replaces = null, // CombinationRegistration
) {
  console.assert(
    combination.dog.inReview !== undefined,
    'Combination without inReview',
  );
  console.assert(
    !replaces || (!!replaces && !!gradeMembership),
    'Replacement without gradeMembership',
  );
  return combination.dog.inReview === false
    && !isDogAlreadyRunning(show, combination, registrations, gradeMembership, replaces)
    && hasValidLicense(show, combination)
    && hasValidClubMembership(show, combination)
    && ((replaces && combination.id === replaces.combination.id)
      || !isCombinationAlreadyRegistered(show, combination, registrations))
    && isCombinationInvited(show, combination, invitations);
}

function canAgilityRegister(
  show,
  combination,
  registrations,
  invitations,
  gradeMembership = null,
  replaces = null, // CombinationRegistration
) {
  return canDefaultRegister(
    show,
    combination,
    registrations,
    invitations,
    gradeMembership,
    replaces,
  ) && isDogOldEnough(show, combination.dog)
    && canDogRunAllCourses(show, combination, registrations, replaces);
}

export function canRegister(
  show,
  combination,
  registrations,
  invitations,
  gradeMembership = null,
  replaces = null, // CombinationRegistration
) {
  if (show.sport === 'AGILITY') {
    return canAgilityRegister(
      show,
      combination,
      registrations,
      invitations,
      gradeMembership,
      replaces,
    );
  }

  return canDefaultRegister(
    show,
    combination,
    registrations,
    invitations,
    gradeMembership,
    replaces,
  );
}

//
// Registration period helpers
//

export function eventRegistrationPeriod(event) {
  const shows = event?.shows.filter((s) => s.showType !== 'PLACEHOLDER');
  return (shows?.length > 0)
    ? shows.map(
      (s) => s.registrationPeriod,
    ).reduce(getCombinedRanges)
    : null;
}

export function eventPreregistrationPeriod(event) {
  const shows = event?.shows.filter((s) => s.showType !== 'PLACEHOLDER');
  return (shows?.length > 0)
    ? shows.map(
      (s) => s.preregistrationPeriod,
    ).reduce(getCombinedRanges)
    : null;
}

//
// Event options
//

export function getEventGroupGradeOptions(
  show,
  combination,
  registration,
  gradeMembership,
  registrations,
  invitations,
) {
  // All normal options
  return getGroupGradeOptions(
    show,
    combination,
    gradeMembership,
  ).map((gg) => ({
    ...gg,
    show,
  }));
}

export function getEventRegistrationReplacementOptions(
  event,
  combination,
  registration,
  registrations,
  invitations,
  minimumDate = null,
  gradeMembership = null,
  categoryMembership = null,
  debutant = false,
) {
  const shows = event.shows
    // Same costs, only shows after minimumDate
    .filter(
      // Same costs
      (s) => s.registrationCosts === registration.show.registrationCosts
        // If set, only consider shows from minimumDate
        && (!minimumDate || !isBefore(s.duration.start, minimumDate))
        // And just generally eligible
        && isEligible(
          event,
          s,
          combination,
          gradeMembership,
          categoryMembership,
          debutant,
        )
        // And we are not currently already registered for that show, because that
        // would lead to double selections
        && !registrations.find(
          (otherRegistration) => otherRegistration.id !== registration.id
            && otherRegistration.combination.id === registration.combination.id
            && !otherRegistration.registered.end
            && otherRegistration.groupGrade.grade.id === registration.groupGrade.grade.id
            && otherRegistration.show.id === s.id,
        )
        && canRegister(
          s,
          combination,
          registrations,
          invitations,
          gradeMembership,
          // with replacement
          registration,
        ),
    );

  // Get all groupGrades we could register in
  const allGroupGradeOptions = shows.reduce(
    (groupGradeOptions, show) => [
      ...groupGradeOptions,
      ...getEventGroupGradeOptions(
        show,
        combination,
        registration,
        gradeMembership,
        registrations,
        invitations,
      )],
    [],
  );

  // Calculate if our only option is to change date
  const mustChangeDay = !allGroupGradeOptions.find(
    (o) => isEqualRange(o.show.duration, registration.show.duration),
  );
  const hasChangedDay = registration.replaces && !isEqual(
    registration.show.duration.start,
    registration.replaces.show.duration.start,
  );

  // If so, we add the option to unregister
  const groupGrades = ((mustChangeDay || hasChangedDay)
    ? [...allGroupGradeOptions, { id: '', show: registration.show }]
    : allGroupGradeOptions);

  // And then we sort
  return groupGrades.slice().sort((a, b) => {
    // 1. Unregister is always last
    if (!a.id) {
      return 1;
    }
    if (!b.id) {
      return -1;
    }
    // 2. Same groupGrade
    if (a.id === registration.groupGrade.id) {
      return -1;
    }
    if (b.id === registration.groupGrade.id) {
      return 1;
    }
    // 3. Same show
    if (a.show.id === registration.show.id) {
      return -1;
    }
    if (b.show.id === registration.show.id) {
      return 1;
    }
    // 4. Same date
    return compareAsc(a.show.duration.start, b.show.duration.start);
  });

}
